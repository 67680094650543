import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Form, Field } from "react-final-form";

import { RoleSelect } from "./RoleSelect";
import { CompanyWorkflowSelect } from "./CompanyWorkflowSelect";
import { CompanySelect } from "./CompanySelect";
import { User } from "./User";
import { ActivatedSelect } from "./ActiveSelect";

import { modularScale } from "@otta/design-tokens";
import { Button, ErrorText, InputField, Text } from "@otta/design";
import { Spinner } from "@otta/shared-components";
import {
  UsersDocument,
  RoleType,
  CompanyWorkflowStatus,
} from "@toolbox/schema";
import { useQuery } from "@toolbox/apollo";
import { FetchMore } from "@toolbox/components/FetchMore";

export function AllUsers(): React.ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();

  const email = searchParams.get("email");
  const role = searchParams.get("role") as RoleType | null;
  const companyWorkflowStatus = searchParams.get("company_status") as
    | CompanyWorkflowStatus
    | "NONE"
    | null;
  const company = searchParams.get("company");
  const activated = useMemo(() => {
    const activatedParam = searchParams.get("activated");
    if (activatedParam === "YES") {
      return true;
    }
    if (activatedParam === "NO") {
      return false;
    }

    return null;
  }, [searchParams]);

  const companyWorkflowStatuses = useMemo(() => {
    if (role !== RoleType.CompanyRecruiter) {
      return undefined;
    }

    if (!companyWorkflowStatus) {
      return [
        CompanyWorkflowStatus.Changed,
        CompanyWorkflowStatus.Completed,
        CompanyWorkflowStatus.CompletedByUser,
      ];
    }

    if (companyWorkflowStatus === "NONE") {
      return null;
    }

    return [companyWorkflowStatus];
  }, [companyWorkflowStatus, role]);

  const { data, loading, fetchMore, error } = useQuery(UsersDocument, {
    variables: {
      email: email ?? undefined,
      role: role ?? undefined,
      companyWorkflowStatus: companyWorkflowStatuses,
      companyId: company ?? undefined,
      activated: activated ?? undefined,
      limit: 15,
      offset: 0,
    },
  });

  const handleSubmit = useCallback(
    (values: { email: string | null }) => {
      if (values.email) {
        const newParams = new URLSearchParams(searchParams);

        newParams.set("email", values.email);

        setSearchParams(newParams);
      }
    },
    [searchParams, setSearchParams]
  );

  const handleClear = useCallback(() => {
    const newParams = new URLSearchParams(searchParams);

    newParams.delete("email");

    setSearchParams(newParams);
  }, [searchParams, setSearchParams]);

  const handleRoleChange = useCallback(
    (v: RoleType | null) => {
      const newParams = new URLSearchParams(searchParams);

      if (v !== RoleType.CompanyRecruiter) {
        newParams.delete("company_status");
        newParams.delete("company");
        newParams.delete("activated");
      }

      if (v) {
        newParams.set("role", v);
      } else {
        newParams.delete("role");
      }

      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  const handleActivatedChange = useCallback(
    (v: boolean | null) => {
      const newParams = new URLSearchParams(searchParams);

      if (v === null) {
        newParams.delete("activated");
      } else {
        newParams.set("activated", v ? "YES" : "NO");
      }

      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );
  const handleWorkflowStatusChange = useCallback(
    (v: CompanyWorkflowStatus | "NONE" | null) => {
      const newParams = new URLSearchParams(searchParams);

      newParams.delete("company");

      if (v) {
        newParams.set("company_status", v);
      } else {
        newParams.delete("company_status");
      }

      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  const handleCompanyChange = useCallback(
    (v: string | null) => {
      const newParams = new URLSearchParams(searchParams);

      if (v) {
        newParams.set("company", v);
      } else {
        newParams.delete("company");
      }

      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  const users = data?.users ?? [];

  return (
    <>
      <div
        style={{
          width: "100%",
          margin: "0 auto",
          maxWidth: 1300,
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: modularScale(),
          flexWrap: "wrap",
          marginTop: 10,
        }}
      >
        <Form<{ email: string | null }>
          onSubmit={handleSubmit}
          initialValues={{ email }}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "flex-end",
                  gap: modularScale(),
                }}
              >
                <div style={{ minWidth: 200, maxWidth: 500 }}>
                  <Field name="email">
                    {({ input }) => (
                      <InputField
                        label="Email"
                        {...input}
                        type="email"
                        autoFocus
                        placeholder="anyone@wttj.co"
                      />
                    )}
                  </Field>
                </div>

                <Button level="secondary" type="submit">
                  Search
                </Button>
                {values.email && (
                  <Button level="secondary" type="button" onClick={handleClear}>
                    Clear
                  </Button>
                )}
              </div>
            </form>
          )}
        </Form>
        <RoleSelect value={role} onChange={handleRoleChange} />
        {role === RoleType.CompanyRecruiter && (
          <>
            <ActivatedSelect
              value={activated}
              onChange={handleActivatedChange}
            />
            <CompanyWorkflowSelect
              value={companyWorkflowStatus}
              onChange={handleWorkflowStatusChange}
            />

            {companyWorkflowStatuses && (
              <CompanySelect
                label="Company"
                value={company}
                statuses={companyWorkflowStatuses}
                onChange={handleCompanyChange}
              />
            )}
          </>
        )}
      </div>

      <div style={{ width: "100%", margin: "0 auto", maxWidth: 1300 }}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <ErrorText>Something went wrong loading users</ErrorText>
        ) : users.length ? (
          <>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                gap: modularScale(-5),
                marginTop: modularScale(),
                marginBottom: modularScale(),
              }}
            >
              {data?.users?.map(user => (
                <User key={user.id} {...user} />
              ))}
            </div>

            <FetchMore fetchMore={fetchMore} offset={users.length} />
          </>
        ) : (
          <Text bold size={2}>
            No users found
          </Text>
        )}
      </div>
    </>
  );
}
