import { DocumentNode, useMutation } from "@apollo/client";
import { FieldArray, Form, Formik } from "formik";
import { useCallback, useMemo } from "react";
import styled from "@xstyled/styled-components";

import {
  Button,
  Card,
  FieldWrapper,
  Fieldset,
  Input,
  SelectField,
  Spacing,
  Text,
  Tipbox,
} from "@otta/design";
import { palette } from "@otta/design-tokens";

type Item = {
  id: string;
  [key: string]: any;
};

const isRequiredField = (name: string) => {
  if ("category" === name) return false;
  if (/(question|answer)$/.test(name)) return false;
  return true;
};

type ChildField = string | Record<string, string[]>;

type Key = string | number | symbol;

const getLabel = (fieldName: string) => {
  switch (fieldName) {
    case "shortTitle":
      return "Title";
    case "longTitle":
      return "Description";
    case "alt":
      return "Alt text";
    case "category":
      return "Video category";
    default:
      return fieldName;
  }
};

const Row = styled.div`
  justify-content: flex-end;
  display: flex;
  gap: 1rem;
`;

const ChildWrapper = styled.div`
  display: grid;
  gap: 1rem;
  padding: 1rem;
`;

const clearEmptyPrompts = (input: Item) => {
  if (input["members"] && input["members"].length > 0) {
    return {
      ...input,
      members: input["members"].map((member: Item) => {
        return {
          ...member,
          prompts: member["prompts"].reduce((acc: Item[], prompt: Item) => {
            if (prompt.question !== "" || prompt.answer !== "") {
              acc.push(prompt);
            }
            return acc;
          }, []),
        };
      }),
    };
  }

  return input;
};

const removeKeys = (obj: Item, omitKeys: Key[]): Item => {
  return Object.keys(obj).reduce((result, key) => {
    if (omitKeys.includes(key)) return result;
    const value = obj[key];
    if (typeof value === "string" && value.trim() === "") return result;
    if (Array.isArray(value)) {
      return {
        ...result,
        [key]: value.map((item: Item) =>
          typeof item === "object" && item !== null
            ? removeKeys(item, omitKeys)
            : item
        ),
      };
    }

    if (typeof value === "object" && value !== null) {
      return { ...result, [key]: removeKeys(value, omitKeys) };
    }

    return { ...result, [key]: value };
  }, {} as Item);
};

/**
 * This evil component summons a nasty form for your graphql object from the ether
 * Just pretend you didn't see this, and please don't imitate it my goodness
 */
export function EditForm<A extends Item>({
  mutation,
  deleteMutation,
  omitFields,
  numberFields,
  childrenFields,
  onClose,
  title,
  item,
}: {
  item: A;
  title?: string;
  mutation: DocumentNode;
  deleteMutation?: DocumentNode;
  onClose?: () => void;
  numberFields?: (keyof A)[];
  omitFields?: (keyof A)[];
  childrenFields?: Record<string, ChildField[]>;
}): React.ReactElement {
  const [update, { error, loading, data }] = useMutation(mutation);
  const numbers = numberFields ?? [];

  const [runDelete, { loading: deleteLoading }] = useMutation(
    deleteMutation ?? mutation // massive, evil hack pls ignore
  );

  const omitKeys = useMemo(
    () => ["id", "__typename", ...(omitFields ?? [])] as (keyof A)[],
    [omitFields]
  );

  const doDelete = useCallback(() => {
    if (window.confirm("Are you sure? Really?")) {
      runDelete({ variables: { id: item.id } });
    }
  }, [runDelete, item]);

  const onSubmit = useCallback(
    (id: string, input: A) => {
      const updatedInput = clearEmptyPrompts(input);
      update({ variables: { id, input: removeKeys(updatedInput, omitKeys) } });
    },
    [update, omitKeys]
  );

  const fields: (keyof A)[] = useMemo(
    () => Object.keys(item).filter(k => !omitKeys.includes(k)),
    [item, omitKeys]
  );

  return (
    <Card>
      <Spacing size={1}>
        {error && <Tipbox level="error">{error.message}</Tipbox>}
        {data && <Tipbox level="positive">Changes saved</Tipbox>}

        <Formik<A>
          onSubmit={values => onSubmit(item.id, values)}
          initialValues={item}
        >
          {({ handleChange, values, setFieldValue }) => (
            <Form>
              <Spacing size={1}>
                <Fieldset legend={title}>
                  {fields.map(name => (
                    <Field
                      setFieldValue={setFieldValue}
                      key={String(name)}
                      name={String(name)}
                      label={getLabel(String(name))}
                      value={(values[name] as string) ?? ""}
                      handleChange={handleChange}
                      numbers={numbers}
                      childrenFields={childrenFields}
                    />
                  ))}
                </Fieldset>
                <Row>
                  <Button
                    type="submit"
                    level="primary"
                    disabled={loading || deleteLoading}
                  >
                    Save
                  </Button>
                  {!!onClose && (
                    <Button
                      type="button"
                      level="secondary"
                      onClick={() => onClose()}
                    >
                      Close
                    </Button>
                  )}
                  {!!deleteMutation && (
                    <Button
                      type="button"
                      level="destructive"
                      disabled={loading || deleteLoading}
                      onClick={() => doDelete()}
                    >
                      Delete
                    </Button>
                  )}
                </Row>
              </Spacing>
            </Form>
          )}
        </Formik>
      </Spacing>
    </Card>
  );
}

const Field = ({
  value,
  name,
  label,
  handleChange,
  numbers,
  childrenFields,
  setFieldValue,
}: {
  value: unknown;
  name: string;
  label?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  numbers: Key[];
  childrenFields?: Record<string, ChildField[]>;
  setFieldValue?: (field: string, value: string | null) => void;
}) => {
  const fieldName = name.split(".").at(-1);
  const fields = useMemo(() => {
    if (
      Array.isArray(value) &&
      childrenFields &&
      fieldName &&
      childrenFields[fieldName]
    ) {
      return childrenFields[fieldName];
    }
  }, [childrenFields, value, fieldName]);

  if (Array.isArray(value) && fieldName) {
    return (
      <FieldArray
        name={name}
        render={({ push, handleRemove }) => (
          <div>
            {value.map((item, index) => {
              const parentName = `${name}.${index}`;
              return (
                <ChildWrapper key={item.id || index}>
                  {fields?.map(field => {
                    if (typeof field === "string") {
                      return (
                        <Field
                          key={field}
                          label={field}
                          name={`${parentName}.${field}`}
                          value={item[field] ?? ""}
                          handleChange={handleChange}
                          numbers={numbers}
                        />
                      );
                    }

                    const childName = Object.keys(field)[0];
                    return (
                      <Field
                        key={label}
                        label={label}
                        name={`${parentName}.${childName}`}
                        value={item[childName] ?? ""}
                        handleChange={handleChange}
                        numbers={numbers}
                        childrenFields={field}
                      />
                    );
                  })}

                  <div>
                    <Button
                      level="destructive"
                      size="small"
                      type="button"
                      onClick={handleRemove(index)}
                    >
                      Remove {fieldName.slice(0, -1)}
                    </Button>
                  </div>
                </ChildWrapper>
              );
            })}
            {fields && (
              <Button
                level="primary"
                size="small"
                type="button"
                onClick={() => {
                  type NewField = Record<
                    string,
                    string | Record<string, string>[]
                  >;

                  const transformFields = (fields: ChildField[]): NewField => {
                    return fields.reduce((acc, field) => {
                      if (typeof field === "string") {
                        return {
                          ...acc,
                          [field]: "",
                        };
                      }

                      Object.entries(field).forEach(([key, values]) => {
                        acc[key] = [
                          values.reduce((obj, value) => {
                            obj[value] = "";
                            return obj;
                          }, {} as Record<string, string>),
                        ];
                      });
                      return acc;
                    }, {} as NewField);
                  };

                  push(transformFields(fields));
                }}
              >
                Add {fieldName.slice(0, -1)}
              </Button>
            )}
          </div>
        )}
      />
    );
  }

  return (
    <FieldWrapper
      label={label || name}
      fieldError={undefined}
      required={isRequiredField(name)}
    >
      {({ field }) => (
        <>
          {name === "category" && setFieldValue ? (
            <CategoryDropDown
              onChange={v => setFieldValue("category", v)}
              value={(value as string) ?? null}
            />
          ) : (
            <Input
              name={name}
              onChange={handleChange}
              type={numbers.includes(name) ? "number" : "text"}
              value={(value as string) ?? ""}
              maxLength={name === "mission" ? 150 : undefined}
              {...field}
            />
          )}
          {name === "mission" && (
            <Text size={-1} color={palette.grayscale.shade600}>
              Mission has max character of 150
            </Text>
          )}
        </>
      )}
    </FieldWrapper>
  );
};

function CategoryDropDown({
  onChange,
  value,
}: {
  onChange(set: string | null): void;
  value: string | null;
}): React.ReactElement {
  const options = useMemo(
    () => [
      { label: "A day in the life of", value: "A day in the life of" },
      { label: "Job interview", value: "Job interview" },
      { label: "Yes we care", value: "Yes we care" },
      { label: "World of", value: "World of" },
      { label: "Open house", value: "Open house" },
    ],
    []
  );

  const val = useMemo(
    () => options.find(({ value: v }) => v === value),
    [options, value]
  );

  return (
    <SelectField
      isClearable
      options={options}
      onChange={v => {
        v?.value ? onChange(v.value) : onChange(null);
      }}
      value={val}
    />
  );
}
