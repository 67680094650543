import Select from "react-select";
import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";

import { CurrentRoleType, useUser } from "@toolbox/utils/user";
import {
  UpdateJobWorkflowStatusDocument,
  JobWorkflowStatus,
} from "@toolbox/schema";

interface WorkflowSelectProps {
  jobId: string;
  workflowStatus: JobWorkflowStatus;
  onComplete: () => void;
}

const SelectWrapper = styled.div`
  width: 100%;
  max-width: 300px;
`;

type OptionType = {
  value: string;
  label: string;
};

const statuses = [
  { value: JobWorkflowStatus.Archived, label: "Archived" },
  { value: JobWorkflowStatus.Changed, label: "Changed" },
  { value: JobWorkflowStatus.Completed, label: "Completed" },
  { value: JobWorkflowStatus.CompletedByUser, label: "Completed by user" },
  { value: JobWorkflowStatus.Disapproved, label: "Disapproved" },
  { value: JobWorkflowStatus.Exception, label: "Exception" },
  { value: JobWorkflowStatus.PreApproval, label: "Pre-Approval" },
  { value: JobWorkflowStatus.QaExternal, label: "QA (External)" },
  { value: JobWorkflowStatus.QaInternal, label: "QA (Internal)" },
  { value: JobWorkflowStatus.QueuedExternal, label: "Queued (External)" },
  { value: JobWorkflowStatus.RecruiterDraft, label: "Recruiter Draft" },
];

export function WorkflowSelect({
  jobId,
  workflowStatus,
  onComplete,
}: WorkflowSelectProps): React.ReactElement | null {
  const { role } = useUser();

  const [updateMutation] = useMutation(UpdateJobWorkflowStatusDocument);

  if (role !== CurrentRoleType.Admin) {
    return null;
  }

  const handleChange = async (e: OptionType | null) => {
    if (!e?.value) {
      return null;
    }

    const { data } = await updateMutation({
      variables: {
        id: jobId,
        workflowStatus: e.value as JobWorkflowStatus,
      },
    });

    if (data) {
      onComplete();
    }
  };

  return (
    <SelectWrapper data-testid="workflow-select-field">
      <Select
        options={statuses}
        value={statuses.find(option => option.value === workflowStatus)}
        menuPlacement="top"
        onChange={handleChange}
        aria-label="workflow-select-label"
      />
    </SelectWrapper>
  );
}
