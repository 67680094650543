import { useMutation } from "@apollo/client";
import deepEqual from "deep-equal";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import styled from "@xstyled/styled-components";

import { AcquisitionChannels } from "./AcquisitionChannels";
import { CreateJob } from "./CreateJob";
import { ScraperUrl } from "./ScraperUrl";
import { SectorTags } from "./SectorTags";
import { useHasFullManageCompanyPermission } from "./useHasFullManageCompanyPermission";
import { CompanySales } from "./CompanySales";

import { Button, Checkbox, Spacing, Text, Toast } from "@otta/design";
import { modularScale, pxToRem } from "@otta/design-tokens";
import { Loading, Mutation } from "@otta/shared-components";
import { useQuery } from "@toolbox/apollo";
import { StyledButton } from "@toolbox/components/Button";
import { Card } from "@toolbox/components/Card";
import { BulletPointField } from "@toolbox/components/Field/BulletPointField";
import { CheckboxField } from "@toolbox/components/Field/CheckboxField";
import { ColourField } from "@toolbox/components/Field/ColourField";
import { CompanyBenefitsField } from "@toolbox/components/Field/CompanyBenefitsFields";
import { DecimalField } from "@toolbox/components/Field/DecimalField";
import { IntegerField } from "@toolbox/components/Field/IntegerField";
import { LinkField } from "@toolbox/components/Field/LinkField";
import { MultiUploadField } from "@toolbox/components/Field/MultiUploadField";
import { ProductCheckboxField } from "@toolbox/components/Field/ProductCheckboxField";
import { ReorderableBulletPointField } from "@toolbox/components/Field/ReorderableBulletPointField";
import { RequiredOfficeDaysField } from "@toolbox/components/Field/RequiredOfficeDaysField";
import { TextField } from "@toolbox/components/Field/TextField";
import { TextareaField } from "@toolbox/components/Field/TextareaField";
import { UploadField } from "@toolbox/components/Field/UploadField";
import { H3, H4 } from "@toolbox/components/Headings";
import { Link } from "@toolbox/components/Link";
import { Space } from "@toolbox/components/Space";
import { ChangesTable } from "@toolbox/components/Table/ChangesTable";
import { tableHeadings } from "@toolbox/constants/changeTableHeadings";
import { Articles } from "@toolbox/containers/Articles";
import { CompanyPeopleProgressing } from "@toolbox/containers/CompanyPeopleProgressing";
import { Founders } from "@toolbox/containers/Founders";
import { Funding } from "@toolbox/containers/Funding";
import {
  AllAcquisitionChannelsDocument,
  CompanyAdditionalInfoDocument,
  CompanyDiversityInitiativeInput,
  CompanyDocument,
  CompanyHasAtsIntegrationDocument,
  CompanyJobsDocument,
  CompanyMarketBulletsDocument,
  CompanyProductsDocument,
  CompanyWorkflowStatus,
  CreateCompanyAdditionalInfoBulletDocument,
  CreateCompanyMarketBulletDocument,
  DeleteCompanyAdditionalInfoBulletDocument,
  DeleteCompanyMarketBulletDocument,
  DeleteCompanyPhotoDocument,
  JobWorkflowStatus,
  RefreshJobApplicationQuestionsDocument,
  RegionsDocument,
  ResetAtsIntegrationDocument,
  UpdateCompanyAdditionalInfoBulletDocument,
  UpdateCompanyDocument,
  UpdateCompanyMarketBulletDocument,
  UploadCompanyFaviconDocument,
  UploadCompanyLogoDocument,
  UploadCompanyPhotoDocument,
} from "@toolbox/schema";
import { handleMutationError } from "@toolbox/utils/error";
import { CurrentRoleType } from "@toolbox/utils/user";
import { ProductBubbles } from "@toolbox/components/ProductBubbles";
import { PlanCallout } from "@toolbox/components/PlanCallout";

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  padding: lg;
  padding-top: 0;
`;

const Content = styled.div`
  max-width: 600px;
  width: 100%;
`;

const Divider = styled.hr`
  margin-top: -20;
  margin-bottom: 20;
`;

const StyledToast = styled(Toast).attrs({ as: "span" })``;

function CopyText({ children }: { children: string }): ReactElement {
  const [toastVisible, setToastVisible] = useState(false);

  useEffect(() => {
    if (toastVisible) {
      const timer = setTimeout(() => {
        setToastVisible(false);
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [toastVisible]);

  const onClick = () => {
    navigator.clipboard.writeText(children);
    setToastVisible(true);
  };

  return (
    <StyledToast isVisible={toastVisible} toastMessage="ID copied to clipboard">
      <Text as="span" bold onClick={onClick} style={{ cursor: "pointer" }}>
        {children}
      </Text>
    </StyledToast>
  );
}

const StyledCard = styled(Card)`
  margin: 0;
  > div {
    margin: 0;
    padding: lg;

    > div {
      margin: 0;
    }
  }
`;

const VisaSponsorshipFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: lg;
`;

const VisaCountryRow = styled.div`
  > p:first-child,
  > div:first-child {
    min-width: ${pxToRem(82)};
  }

  div {
    display: flex;
  }

  input {
    &:checked {
      &::before {
        transform: scale(1);
      }
      background-color: yellow-500;
    }

    &::before {
      content: "";
      border-radius: 50%;
      box-shadow: inset ${modularScale()} ${modularScale()} black;
      height: ${pxToRem(7.5)};
      transform: scale(0);
      width: ${pxToRem(7.5)};
    }

    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: gray-200;
    border-radius: 50%;
    display: grid;
    margin-right: 6;
    place-content: center;
    height: ${pxToRem(20)};
    width: ${pxToRem(20)};
    cursor: pointer;
  }

  label {
    font-weight: unset;
  }

  display: flex;
  gap: 26;
`;

type VisaSponsorshipStatus = {
  location: string;
  offersVisa: boolean | null;
  region: {
    id: string;
  };
};

interface VisaSponsorshipFieldProps {
  onChange: (event: any) => void;
  value: VisaSponsorshipStatus[];
}

function VisaSponsorshipField({
  onChange,
  value,
}: VisaSponsorshipFieldProps): ReactElement {
  const { data, loading } = useQuery(RegionsDocument);

  const [visaSponsorshipMap, setVisaSponsorshipMap] = useState<
    VisaSponsorshipStatus[]
  >([]);

  const handleSetVisaSponsorship = (name: string, value: boolean | null) => {
    const newVisaSponsorshipMap = [...visaSponsorshipMap];
    const indexToUpdate = newVisaSponsorshipMap.findIndex(
      region => region.location === name
    );

    newVisaSponsorshipMap[indexToUpdate].offersVisa = value;

    setVisaSponsorshipMap(newVisaSponsorshipMap);
    onChange(newVisaSponsorshipMap);
  };

  const possibleRegionValues: {
    display: string;
    value: boolean | null;
  }[] = [
    { display: "Yes", value: true },
    { display: "No", value: false },
    { display: "Not sure", value: null },
  ];

  useEffect(() => {
    // Set the visa sponsorship map appropriately once the data has come back
    if (data) {
      setVisaSponsorshipMap(
        data.regions.map(loc => ({
          location: loc.name,
          offersVisa:
            value.find(region => region.location === loc.name)?.offersVisa ??
            null,
          region: {
            id: loc.id,
          },
        }))
      );
    }
  }, [data, value]);

  return (
    <StyledCard>
      <VisaSponsorshipFieldWrapper>
        <VisaCountryRow>
          <Text bold> Location </Text>
          <Text bold>
            Can the company sponsor visas for jobs in this location?
          </Text>
        </VisaCountryRow>

        {loading ? (
          <Loading />
        ) : (
          data?.regions.map((loc, index) => (
            <VisaCountryRow key={loc.name}>
              <div> {loc.displayName} </div>
              <>
                {possibleRegionValues.map(v => {
                  const id = v.display.toLowerCase().replace(/\s/g, "-");
                  return (
                    <div key={`${loc.name}-${id}`}>
                      <input
                        checked={
                          visaSponsorshipMap[index]?.offersVisa === v.value
                        }
                        onChange={() =>
                          handleSetVisaSponsorship(loc.name, v.value)
                        }
                        name={`${loc.name}-${id}`}
                        id={`${loc.name}-${id}`}
                        data-testid={`${loc.name}-${id}`}
                        type="radio"
                      />
                      <label
                        onClick={() =>
                          handleSetVisaSponsorship(loc.name, v.value)
                        }
                        htmlFor={`${loc.name}-${v.value}`}
                      >
                        {v.display}
                      </label>
                    </div>
                  );
                })}
              </>
            </VisaCountryRow>
          ))
        )}
      </VisaSponsorshipFieldWrapper>
    </StyledCard>
  );
}

export function CompanyPage({
  companyId,
  role,
}: {
  companyId: string;
  role: CurrentRoleType;
}): React.ReactElement | null {
  const [officeFullyRemote, setOfficeFullyRemote] = useState(false);

  const { data, error } = useQuery(CompanyDocument, {
    variables: {
      id: companyId,
    },
  });

  const { data: hasAtsIntegrationData } = useQuery(
    CompanyHasAtsIntegrationDocument,
    {
      variables: { id: companyId },
      errorPolicy: "ignore",
      skip: role !== CurrentRoleType.Admin,
    }
  );

  const { data: allJobsData } = useQuery(CompanyJobsDocument, {
    variables: { id: companyId },
  });

  const { data: productsData } = useQuery(CompanyProductsDocument, {
    variables: {
      id: companyId,
    },
    skip: role !== CurrentRoleType.Admin,
  });

  const { data: acquisitionChannelsData } = useQuery(
    AllAcquisitionChannelsDocument
  );

  const allAcquisitionChannelOptions = useMemo(() => {
    return (acquisitionChannelsData?.companyAcquisitionChannels ?? []).map(
      ac => ({
        label: ac.value,
        value: ac.id,
      })
    );
  }, [acquisitionChannelsData]);

  const [updateCompanyMutation] = useMutation(UpdateCompanyDocument, {
    onError: handleMutationError,
    refetchQueries: [{ query: CompanyDocument, variables: { id: companyId } }],
  });

  const [refreshQuestions] = useMutation(
    RefreshJobApplicationQuestionsDocument,
    {
      onError: handleMutationError,
      variables: { companyId },
    }
  );

  const handleClickRefreshQuestions = async () => {
    await refreshQuestions();
    window.alert(
      "Ok, the application questions for all jobs for this company will be refreshed shortly"
    );
  };

  const handleOfficeDaysSubmit = ({
    requiredOfficeDaysRange,
  }: {
    requiredOfficeDaysRange: {
      minDaysInOffice: string | null | undefined;
      maxDaysInOffice: string | null | undefined;
    };
  }) => {
    if (requiredOfficeDaysRange) {
      updateCompanyMutation({
        variables: {
          id,
          input: {
            minDaysInOffice: requiredOfficeDaysRange.minDaysInOffice
              ? parseInt(requiredOfficeDaysRange.minDaysInOffice)
              : null,
            maxDaysInOffice: requiredOfficeDaysRange.maxDaysInOffice
              ? parseInt(requiredOfficeDaysRange.maxDaysInOffice)
              : null,
          },
        },
      });
    }
  };

  const handleVisaSponsorshipSubmit = (
    visaSponsorshipChanges: VisaSponsorshipStatus[]
  ) => {
    const changes = visaSponsorshipChanges
      // Filter out null values so we remove the entry for country-region visa data
      // in the company_visa_sponsorship_countries table
      .filter(region => region.offersVisa !== null)
      .map(({ location, offersVisa, region }) => ({
        location,
        offersVisa: !!offersVisa,
        regionId: region.id,
      }));
    updateCompanyMutation({
      variables: {
        id,
        input: {
          visaSponsorshipCountries: changes,
        },
      },
    });
  };

  const hasAtsIntegration = !!hasAtsIntegrationData?.company?.hasAtsIntegration;
  const hasManagePermission: boolean = useHasFullManageCompanyPermission(
    data?.company?.workflowStatus,
    role
  );

  const hasDirectlyPostedJobs = useMemo(() => {
    const jobs = allJobsData?.company?.listJobs;
    if (jobs) {
      return (
        jobs.filter(job => {
          return (
            job.originalUrl === null &&
            [
              JobWorkflowStatus.Completed,
              JobWorkflowStatus.CompletedByUser,
              JobWorkflowStatus.Changed,
              JobWorkflowStatus.PreApproval,
              JobWorkflowStatus.QueuedInternal,
              JobWorkflowStatus.QueuedExternal,
              JobWorkflowStatus.QaExternal,
              JobWorkflowStatus.QaInternal,
            ].includes(job.workflowStatus)
          );
        }).length > 0
      );
    } else {
      return false;
    }
  }, [allJobsData?.company?.listJobs]);

  if (error) {
    return (
      <Wrapper>
        A company with this ID doesn&apos;t exist.
        <br />
        {error.message}
      </Wrapper>
    );
  }

  if (!data?.company) {
    return <Wrapper />;
  }

  const {
    id,
    externalId,
    name,
    urlSafeName,
    scraperUrl,
    scraperType,
    companiesHouseName,
    hqAddress,
    parsedHqAddress,
    mission,
    websiteUrl,
    shortDescription,
    trustpilotUrl,
    glassdoorUrl,
    crunchbaseUrl,
    visaSponsorshipCountries,
    linkedinUrl,
    trustpilotRating,
    glassdoorRating,
    logoPath,
    numberEmployees,
    primaryColour,
    faviconPath,
    photos,
    percentageFemale,
    lastWorkflowStatusChangeBy,
    yearEmployeeGrowthPercentage,
    hubspotId,
    dealroomId,
    trackingCodeName,
    bcorp,
    values,
    diversityInitiatives,
    acquisitionChannels,
    workflowStatus,
    changes,
    minDaysInOffice,
    maxDaysInOffice,
    paused,
    currentCustomerPlans,
  } = data.company;

  const companyTableHeadings = tableHeadings.filter(
    heading => heading !== "Changed by"
  );
  const showCompanyChangedSection =
    workflowStatus === CompanyWorkflowStatus.Changed;

  return (
    <Wrapper>
      <Content>
        <Spacing size={-3}>
          <Text size={3}>
            {name}
            <ProductBubbles company={data.company} />
          </Text>
          {currentCustomerPlans && currentCustomerPlans?.length > 0 && (
            <PlanCallout currentCustomerPlans={currentCustomerPlans} />
          )}

          <Text>
            External ID: <CopyText>{externalId}</CopyText>
          </Text>

          <Link to={`${import.meta.env.VITE_URL}/companies/${urlSafeName}`}>
            <H4>{`${import.meta.env.VITE_URL}/companies/${urlSafeName}`}</H4>
          </Link>
          {lastWorkflowStatusChangeBy && (
            <H4>
              Last status change by: {lastWorkflowStatusChangeBy.firstName}{" "}
              {lastWorkflowStatusChangeBy.lastName}
            </H4>
          )}
          {showCompanyChangedSection && (
            <Spacing size={-6}>
              <Text size={-1} bold>
                Changes
              </Text>
              <ChangesTable
                headings={companyTableHeadings}
                changes={changes}
                isFullWidth
                data-testid="company-changes-table"
              />
            </Spacing>
          )}
          <H3>Section 1</H3>
          <Divider />
          <TextField
            label="Company name"
            value={name}
            mutation={UpdateCompanyDocument}
            fieldName="name"
            id={id}
          />
          <TextField
            label="Company Legal Name"
            value={companiesHouseName}
            mutation={UpdateCompanyDocument}
            fieldName="companiesHouseName"
            id={id}
          />
          <UploadField
            label="Company logo"
            id={id}
            value={logoPath}
            mutation={UploadCompanyLogoDocument}
            display={src => <img src={src} width="150px" alt="Company logo" />}
            accept={["image/png", "image/jpg", "image/jpeg", "image/svg+xml"]}
          />
          <UploadField
            label="Company favicon"
            id={id}
            value={faviconPath}
            mutation={UploadCompanyFaviconDocument}
            display={src => (
              <img src={src} width="128px" alt="Company favicon" />
            )}
            accept={["image/png", "image/jpg", "image/jpeg"]}
          />
          <ColourField
            label="Primary colour"
            value={primaryColour}
            mutation={UpdateCompanyDocument}
            fieldName="primaryColour"
            id={id}
          />
          {hasManagePermission && (
            <MultiUploadField
              label="Company photos"
              id={id}
              values={photos}
              uploadMutation={UploadCompanyPhotoDocument}
              deleteMutation={DeleteCompanyPhotoDocument}
              display={({ path }) => (
                <img src={path} width="150px" alt="Company" />
              )}
              accept={["image/png", "image/jpg", "image/jpeg"]}
            />
          )}

          <Space vertical size={24} />
          <div>
            <ScraperUrl
              companyId={id}
              url={scraperUrl ?? ""}
              type={scraperType}
              disabled={hasDirectlyPostedJobs && role !== CurrentRoleType.Admin}
              updateCompany={({ url, type }) => {
                updateCompanyMutation({
                  variables: {
                    id,
                    input: {
                      scraperUrl: url,
                      scraperType: type,
                    },
                  },
                });
              }}
            />
            {hasDirectlyPostedJobs &&
              !hasAtsIntegration &&
              role !== CurrentRoleType.Admin && (
                <Text>
                  This company has posted a job internally. Ask an admin to add
                  the scraper url.
                </Text>
              )}
            {hasDirectlyPostedJobs &&
              !hasAtsIntegration &&
              role === CurrentRoleType.Admin && (
                <Text>
                  This company has posted a job internally. Please use the
                  re-map tool instead of linking the scraper URL above.
                </Text>
              )}
          </div>

          {hasAtsIntegration && role === CurrentRoleType.Admin && (
            <Mutation
              mutation={ResetAtsIntegrationDocument}
              variables={{ companyId: id }}
            >
              {mutation => (
                <StyledButton
                  level="secondary"
                  type="button"
                  onClick={async () => {
                    await mutation();
                    alert("ATS credentials reset successfully!");
                  }}
                >
                  Reset ATS Credentials
                </StyledButton>
              )}
            </Mutation>
          )}

          <Space vertical size={24} />

          <LinkField
            label="Website URL"
            value={websiteUrl}
            mutation={UpdateCompanyDocument}
            fieldName="websiteUrl"
            id={id}
          />
          <LinkField
            label="LinkedIn URL"
            value={linkedinUrl}
            mutation={UpdateCompanyDocument}
            fieldName="linkedinUrl"
            id={id}
          />
          <LinkField
            label="Trustpilot URL"
            value={trustpilotUrl}
            mutation={UpdateCompanyDocument}
            fieldName="trustpilotUrl"
            id={id}
          />
          <DecimalField
            label="Trustpilot Rating"
            value={trustpilotRating}
            mutation={UpdateCompanyDocument}
            fieldName="trustpilotRating"
            id={id}
            decimalPlaces={1}
          />
          <LinkField
            label="Crunchbase URL"
            value={crunchbaseUrl}
            mutation={UpdateCompanyDocument}
            fieldName="crunchbaseUrl"
            id={id}
          />
          <LinkField
            label="Glassdoor URL"
            value={glassdoorUrl}
            mutation={UpdateCompanyDocument}
            fieldName="glassdoorUrl"
            id={id}
          />
          <DecimalField
            label="Glassdoor Rating"
            value={glassdoorRating}
            mutation={UpdateCompanyDocument}
            fieldName="glassdoorRating"
            id={id}
            decimalPlaces={1}
          />
          <IntegerField
            label="Number of employees"
            value={numberEmployees}
            mutation={UpdateCompanyDocument}
            fieldName="numberEmployees"
            id={id}
          />
          {hasManagePermission && (
            <>
              <IntegerField
                label="% Female"
                value={percentageFemale}
                mutation={UpdateCompanyDocument}
                fieldName="percentageFemale"
                id={id}
              />
              <IntegerField
                label="12 month employee growth %"
                value={yearEmployeeGrowthPercentage}
                mutation={UpdateCompanyDocument}
                fieldName="yearEmployeeGrowthPercentage"
                id={id}
                allowNegative={true}
              />
            </>
          )}
          <Funding companyId={id} />
          <H3>Section 2</H3>
          <Divider />
          <TextField
            label="Short description"
            value={shortDescription}
            mutation={UpdateCompanyDocument}
            fieldName="shortDescription"
            id={id}
          />
          <SectorTags bcorp={bcorp} id={id} />
          <FinalForm<{
            requiredOfficeDaysRange: {
              minDaysInOffice: string | null | undefined;
              maxDaysInOffice: string | null | undefined;
            };
          }>
            initialValues={{
              requiredOfficeDaysRange: {
                minDaysInOffice: minDaysInOffice?.toString(),
                maxDaysInOffice: maxDaysInOffice?.toString(),
              },
            }}
            initialValuesEqual={deepEqual}
            onSubmit={values => handleOfficeDaysSubmit({ ...values })}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <RequiredOfficeDaysField
                  label="Days per week in office"
                  subText="Leave the maximum blank if they only have a minimum number of office days."
                  onBlur={() => handleSubmit()}
                />
              </form>
            )}
          </FinalForm>

          <Checkbox
            label="Fully remote"
            onChange={() => {
              updateCompanyMutation({
                variables: {
                  id,
                  input: {
                    hqAddress: !officeFullyRemote ? "Fully remote" : "",
                    parsedHqAddress: "",
                  },
                },
              });

              setOfficeFullyRemote(!officeFullyRemote);
            }}
            checked={officeFullyRemote}
          />
          <TextField
            label="HQ Address"
            value={hqAddress}
            mutation={UpdateCompanyDocument}
            fieldName="hqAddress"
            id={id}
            disabled={officeFullyRemote}
          />
          <TextField
            label="Parsed HQ Address"
            value={parsedHqAddress}
            mutation={UpdateCompanyDocument}
            fieldName="parsedHqAddress"
            id={id}
            disabled={officeFullyRemote}
          />

          <TextareaField
            label="Mission"
            value={mission}
            mutation={UpdateCompanyDocument}
            fieldName="mission"
            id={id}
          />
          <Founders companyId={id} />
          <CompanyPeopleProgressing companyId={id} />
          <ReorderableBulletPointField
            label="Values"
            parentId={id}
            data={values}
            handleUpdate={async values => {
              await updateCompanyMutation({
                variables: {
                  id,
                  input: {
                    values: values,
                  },
                },
              });
            }}
          />
          <Text as="label" size={-1} bold htmlFor="visa-sponsorship">
            Visa Sponsorship
          </Text>
          <VisaSponsorshipField
            onChange={handleVisaSponsorshipSubmit}
            value={visaSponsorshipCountries}
          />

          <CompanyBenefitsField label="Benefits Provided" parentId={id} />

          <H3>Section 3</H3>
          <Divider />
          <Articles companyId={id} />

          <BulletPointField
            label="Our take on the market"
            parentId={id}
            itemsQuery={CompanyMarketBulletsDocument}
            fieldName="marketBullets"
            parentName="company"
            createMutation={CreateCompanyMarketBulletDocument}
            updateMutation={UpdateCompanyMarketBulletDocument}
            deleteMutation={DeleteCompanyMarketBulletDocument}
          />
          <BulletPointField
            label="Additional information"
            parentId={id}
            itemsQuery={CompanyAdditionalInfoDocument}
            fieldName="additionalInfoBullets"
            parentName="company"
            createMutation={CreateCompanyAdditionalInfoBulletDocument}
            updateMutation={UpdateCompanyAdditionalInfoBulletDocument}
            deleteMutation={DeleteCompanyAdditionalInfoBulletDocument}
          />
          <ReorderableBulletPointField
            label="Diversity, Equity &amp; inclusion initiatives"
            parentId={id}
            data={diversityInitiatives}
            handleUpdate={async diversityInitiatives => {
              await updateCompanyMutation({
                variables: {
                  id,
                  input: {
                    diversityInitiatives:
                      diversityInitiatives as CompanyDiversityInitiativeInput[],
                  },
                },
              });
            }}
          />
          {hasManagePermission && (
            <Spacing>
              {productsData !== undefined && (
                <Spacing size={-5}>
                  {(productsData?.allProducts ?? []).map(availableProduct => {
                    const companyProducts =
                      productsData?.company?.products.map(
                        product => product.name
                      ) ?? [];

                    return (
                      <ProductCheckboxField
                        key={availableProduct.name}
                        id={id}
                        value={companyProducts.includes(availableProduct.name)}
                        product={availableProduct}
                      />
                    );
                  })}
                </Spacing>
              )}
              <TextField
                label="HubSpot ID"
                value={hubspotId}
                mutation={UpdateCompanyDocument}
                fieldName="hubspotId"
                id={id}
              />
              <CreateJob companyId={companyId} />
            </Spacing>
          )}

          {[
            CurrentRoleType.Admin,
            CurrentRoleType.ExternalResearcher,
            CurrentRoleType.InternalOperator,
          ].includes(role) && (
            <TextField
              label="Dealroom ID"
              value={dealroomId}
              mutation={UpdateCompanyDocument}
              fieldName="dealroomId"
              id={id}
            />
          )}

          {[
            CurrentRoleType.Admin,
            CurrentRoleType.ExternalResearcher,
            CurrentRoleType.InternalOperator,
          ].includes(role) && (
            <TextField
              label="Tracking code name - appends query string parameter (optional)"
              value={trackingCodeName}
              mutation={UpdateCompanyDocument}
              fieldName="trackingCodeName"
              id={id}
            />
          )}

          {[
            CurrentRoleType.Admin,
            CurrentRoleType.ExternalResearcher,
            CurrentRoleType.InternalOperator,
          ].includes(role) && (
            <AcquisitionChannels
              companyId={id}
              selectedAcquisitionChannels={acquisitionChannels}
              allOptions={allAcquisitionChannelOptions}
            />
          )}

          {role === CurrentRoleType.Admin && (
            <Spacing>
              <CheckboxField
                label="Paused company"
                value={paused}
                mutation={UpdateCompanyDocument}
                fieldName="paused"
                id={id}
              />
              <Link to="remap-jobs" underline={false}>
                <Button level="tertiary">Remap jobs</Button>
              </Link>
              <Link to="job-applications" underline={false}>
                <Button level="tertiary">Update response rate</Button>
              </Link>
              {hasAtsIntegration && (
                <Button level="tertiary" onClick={handleClickRefreshQuestions}>
                  Refresh application questions for all jobs
                </Button>
              )}
              {[
                CompanyWorkflowStatus.Completed,
                CompanyWorkflowStatus.Changed,
                CompanyWorkflowStatus.CompletedByUser,
              ].includes(workflowStatus) && (
                <>
                  <H3>Active subscriptions (Excl. Starter)</H3>
                  <Divider />
                  <CompanySales id={id} />
                </>
              )}
            </Spacing>
          )}
        </Spacing>
      </Content>
    </Wrapper>
  );
}
