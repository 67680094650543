import styled from "@xstyled/styled-components";

interface InputProps {
  valid?: boolean;
  error?: boolean;
}

export const Input = styled.input<InputProps>`
  width: 100%;
  line-height: 2;
  font-size: 16px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme, valid, error }) => {
    if (valid) {
      return theme.input.valid.borderColour;
    }
    if (error) {
      return theme.input.error.borderColour;
    }
    return theme.input.borderColour;
  }};
  background-color: ${({ theme }) => theme.palette.white.shade0};
  padding: 0 4px;
  box-sizing: border-box;
  outline: none;

  &:focus {
    border-color: ${({ theme }) => theme.input.focus.borderColour};
  }

  &:disabled {
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.grey.shade400};
  }
`;

export const Label = styled.label`
  width: 100%;
  line-height: 1;
  font-size: 14px;
  text-align: left;
  color: ${({ theme }) => theme.text.colour};
`;
